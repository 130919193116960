<template>
  <div class="store"></div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
};
</script>